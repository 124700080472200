<template>
  <div class="home-info-cards">
    <b-container>
      <swiper class="swiper-navigations" :options="swiperOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
        <swiper-slide class="home-info-card">
          <div class="home-info-card-icon-container ">
            <i class="home-info-card-icon fal fa-solar-system"></i>
          </div>
          <h2 class="font-weight-bolder text-center">{{ $t('home_swiper.1_header') }}</h2>
          <h5 class="mt-auto text-muted text-center">{{ $t('home_swiper.1_text') }}</h5>
        </swiper-slide>
        <swiper-slide class="home-info-card">
          <div class="home-info-card-icon-container ">
            <i class="home-info-card-icon fal fa-thumbs-up"></i>
          </div>
          <h2 class="font-weight-bolder text-center">{{ $t('home_swiper.2_header') }}</h2>
          <h5 class="mt-auto text-muted text-center">{{ $t('home_swiper.2_text') }}</h5>
        </swiper-slide>
        <swiper-slide class="home-info-card">
          <div class="home-info-card-icon-container ">
            <i class="home-info-card-icon fal fa-calculator-alt"></i>
          </div>
          <h2 class="font-weight-bolder text-center">{{ $t('home_swiper.3_header') }}</h2>
          <h5 class="mt-auto text-muted text-center">{{ $t('home_swiper.3_text') }}</h5>
        </swiper-slide>
        <swiper-slide class="home-info-card">
          <div class="home-info-card-icon-container ">
            <i class="home-info-card-icon fal fa-smile-plus"></i>
          </div>
          <h2 class="font-weight-bolder text-center">{{ $t('home_swiper.4_header') }}</h2>
          <h5 class="mt-auto text-muted text-center">{{ $t('home_swiper.4_text') }}</h5>
        </swiper-slide>

        <!-- Add Arrows -->
        <div slot="button-next" class="swiper-button-next d-block d-xl-none" />
        <div slot="button-prev" class="swiper-button-prev d-block d-xl-none" />
      </swiper>
    </b-container>
  </div>
</template>

<script>
import { BContainer } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    BContainer,
    Swiper,
    SwiperSlide
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 4,
      spaceBetween: 50,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        1440: {
          slidesPerView: 4,
          spaceBetween: 50
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50
        },
        1: {
          slidesPerView: 1,
          spaceBetween: 50
        }
      }
    }

    return {
      swiperOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.home-info-cards {
  background-color: #f1f2f6;
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 4rem 0 2rem 0;
}

.home-info-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  .home-info-card-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 8rem;
    border-radius: 2rem;
    background-color: white;
    transition: all 300ms;

    .home-info-card-icon {
      transition: all 300ms;
      font-size: 3rem;
      color: #7367f0;
    }
  }

  h2{
    height: 4rem;
  }
}

.dark-layout {
  .home-info-cards {
    background-color: #283046;
  }

  .home-info-card {
    .home-info-card-icon-container {
      background-color: #7367f0;
      .home-info-card-icon {
        color: white;
      }
    }
  }
}
</style>
