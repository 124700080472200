<template>
  <div class="pb-2">
    <b-container>
      <swiper class="home-swiper swiper-navigations" :options="swiperOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
        <swiper-slide v-for="(data, index) in swiperData" :key="index">
          <b-img class="home-swiper-image" :src="data.img" />
        </swiper-slide>

        <!-- Add Arrows -->
        <div slot="button-next" class="swiper-button-next" />
        <div slot="button-prev" class="swiper-button-prev" />
      </swiper>
    </b-container>
  </div>
</template>

<script>
import { BImg, BContainer } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    BContainer,
    BImg,
    Swiper,
    SwiperSlide
  },
  setup() {
    const swiperData = [{ img: require('@/assets/images/home_swiper/1.jpg') }, { img: require('@/assets/images/home_swiper/2.jpg') }, { img: require('@/assets/images/home_swiper/3.jpg') }]

    const swiperOptions = {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }

    return {
      swiperData,
      swiperOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.home-swiper {
  max-height: 720px;
  .home-swiper-image {
    max-height: 720px;
    width: 100%;
    object-fit: cover;
  }
}
</style>
