<template>
  <div>
    <home-swiper />
    <home-info-cards />
  </div>
</template>

<script>
import HomeSwiper from './HomePartials/HomeSwiper.vue'
import HomeInfoCards from './HomePartials/HomeInfoCards.vue'
import 'swiper/css/swiper.css'
export default {
  components: { HomeSwiper, HomeInfoCards }
}
</script>
